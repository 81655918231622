<template>
  <div class="loading-overlay d-flex justify-content-center align-items-center">
    <div class="d-flex align-items-baseline">
      <b-spinner small label="Checking account"></b-spinner>
      <h4 class="ml-2">Checking account..</h4>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loading',
  components: {},
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.loading-overlay {
    position: absolute;
    left: 0; right: 0;
    top: 0; bottom: 0;
    z-index: 2000;
    width: 100vw;
    height: 100vh;
    background: white;
}
</style>
